<h1 mat-dialog-title>
  <div class="text-center">
    <h4 class="mb-1">{{ title }}</h4>
    <mat-icon class="mat-icon-xlg" color="primary">notification_important</mat-icon>
  </div>
</h1>
<div mat-dialog-content>
  @for (message of messages; track message) {
    <p>{{ message }}</p>
  }
</div>
<div mat-dialog-actions>
  <button i18n mat-raised-button color="primary" (click)="close()" class="m-auto">Ok</button>
</div>