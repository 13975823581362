<div mat-dialog-title>
  <div class="text-center">
    <h4 class="mb-1">{{ title }}</h4>
    <mat-icon class="mat-icon-xlg" color="primary">warning</mat-icon>
  </div>
</div>
<div mat-dialog-content>
  @for (message of messages; track message) {
    <p>{{ message }}</p>
  }
</div>
<div mat-dialog-actions class="flex items-center justify-between pb-4">
  <button i18n mat-raised-button color="warn" (click)="onDismiss()" i18n>No</button>
  <button i18n mat-raised-button color="primary" (click)="onConfirm()" i18n>Yes</button>
</div>