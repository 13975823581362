import { Injectable } from "@angular/core";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "../shared/confirm-dialog/confirm-dialog.component";
import { AlertDialogComponent } from "../shared/alert-dialog/alert-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(public dialog: MatDialog, private snackBar: MatSnackBar) {}

  /**
   * Opens a confirmation dialog which the user can either cancel or accept
   * You can subscribe to the dialog ref to view his answer
   * @param title the title of the dialog
   * @param message the messages
   * @returns the corresponding dialogRef that you can subscribe to
   */
  public openConfirmDialog(title: string, messages: string[]) {
    const dialogData = new ConfirmDialogModel(title, messages);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
      panelClass: ["theme-dialog"],
      hasBackdrop: false,
    });
    return dialogRef;
  }

  /**
   * Opens an alert dialog with a specific message that the user needs to accept
   * @param message the message
   * @returns
   */
  public openAlertDialog(title: string, messages: string[]) {
    const dialogData = new ConfirmDialogModel(title, messages);
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
      panelClass: ["theme-dialog"],
      hasBackdrop: false,
    });
    return dialogRef;
  }

  /**
   * Function used to translate a given text using the translate service and show it on a snackbar
   * @param data the data to be translated
   * @param error a boolean to decide whether it is an error
   */
  public showSnackbar(
    message: string,
    error: boolean = false,
    horizontalPosition: MatSnackBarHorizontalPosition = "end",
    verticalPosition: MatSnackBarVerticalPosition = "top"
  ) {
    // The only thing that changes between an error snackbar and normal is the panelClass to red
    if (error) {
      this.snackBar.open(message, "close", {
        verticalPosition: verticalPosition,
        horizontalPosition: horizontalPosition,
        duration: 15000,
        panelClass: ["error-snackbar"],
      });
    } else {
      this.snackBar.open(message, "close", {
        verticalPosition: verticalPosition,
        horizontalPosition: horizontalPosition,
        duration: 5000,
        panelClass: ["success-snackbar"],
      });
    }
  }
}
